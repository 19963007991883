import { SWRConfig } from 'swr'
import Swap from '../views/Swap'

const IndexPage = ({ totalTx30Days, addressCount30Days, tvl }) => {
    return (
        <SWRConfig
            value={{
                fallback: {
                    totalTx30Days,
                    addressCount30Days,
                    tvl,
                },
            }}
        >
            <Swap />
        </SWRConfig>
    )
}

// export const getStaticProps: GetStaticProps = async () => {}

IndexPage.chains = []

export default IndexPage
